export const styles = {
  VRButton: {
    color: 'black',
    background: 'white',
    border: '2px solid black',
    borderRadius: '4px',
    position: 'absolute',
    bottom: '250px',
    left: '50%',
    transform: 'translateX(-50%)',
    padding: '14px 26px',
    font: '1rem sans-serif',
    outline: 'none',
    zIndex: '999',
    cursor: 'pointer',
  } as React.CSSProperties,
  Instructions: {
    color: 'black',
    background: 'white',
    border: '2px solid black',
    borderRadius: '4px',
    position: 'absolute',
    bottom: '100px',
    left: '50%',
    transform: 'translateX(-50%)',
    padding: '14px 26px',
    font: '1rem sans-serif',
    outline: 'none',
    zIndex: '999',
  } as React.CSSProperties,
};
